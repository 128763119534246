import Vue from 'vue'
import Vuex from 'vuex'
import ClientVuex from 'common/components/client/client-vuex-store';
import FavouriteVuex from 'common/components/favourite/favourite-vuex-store';
import ShopVuex from 'common/components/shop/shop-vuex-store';
import AccountVuex from 'common/components/account/account-vuex-store';

Vue.use(Vuex)

// States defined in each Vuex modules will be stored in local storage
// Mutations of the states will be synchronised across all opened tabs
let modules = {
    ClientVuex,
    FavouriteVuex,
    ShopVuex,
    AccountVuex,
};

let states = {};
let mutations = {};
for (var moduleName in modules) {
    for (var stateName in modules[moduleName].states) {
        states[stateName] = modules[moduleName].states[stateName];
    }
    for (var mutationName in modules[moduleName].mutations) {
        mutations[mutationName] = modules[moduleName].mutations[mutationName];
    }
}

// This function keeps reactivity of the "to" item
// Return false if merge fail, caller should assign to = from instead
function merge(to, from) {
    if (Array.isArray(to) && Array.isArray(from)) {
        for (let i = 0; i < to.length && i < from.length; i++) {
            let success = merge(to[i], from[i]);
            if (!success) to[i] = from[i];
        }
        if (to.length < from.length) {
            for (let i = to.length; i < from.length; i++) {
                to.push(from[i]);
            }
        } else if (to.length > from.length) {
            to.splice(from.length);
        }
        return true;
    } else if ((typeof to === 'object') && (typeof from === 'object')) {
        for (var fromName in from) {
            if (fromName in to) {
                let success = merge(to[fromName], from[fromName]);
                if (!success) to[fromName] = from[fromName];
            } else {
                to[fromName] = from[fromName];
            }
        }
        return true;
    }
    return false;
}

// Values stored in local storage should be user specific
// This function try to get userId from state
// Return 0 if failed
function getUserId(state) {
    var userId = 0;

    if (state && state.__ob__ && state.__ob__.dep && state.__ob__.dep.subs && Array.isArray(state.__ob__.dep.subs)) {
        for (var i = 0; i < state.__ob__.dep.subs.length; i++) {
            var item = state.__ob__.dep.subs[i];
            if (item && item.vm && item.vm.$auth && item.vm.$auth.user) {
                var user = item.vm.$auth.user();
                if (user && user.user && user.user.userId) {
                    userId = user.user.userId;
                }
            }
        }
    }

    return userId;
}

const store = new Vuex.Store({
    state: {
        ...states,
    },

    mutations: {
        initialise(state) {
            var userId = getUserId(state);

            // Load states from local storage
            for (var moduleName in modules) {
                for (var stateName in modules[moduleName].states) {
                    var localStorageItem = window.localStorage.getItem(`Vuex_User${userId}_${moduleName}_${stateName}`);
                    if (localStorageItem) {
                        var newItem = JSON.parse(localStorageItem);
                        var success = merge(state[stateName], newItem);
                        if (!success) state[stateName] = newItem;
                    }
                }
            }
        },

        onStorage(state, event) {
            if (!event.newValue) {
                if (state[event.stateName] != null) state[event.stateName] = null;
            } else {
                var userId = getUserId(state);

                if (userId === event.userId) {
                    var newValue = JSON.parse(event.newValue);
                    var success = merge(state[event.stateName], newValue);
                    if (!success) state[event.stateName] = newValue;
                }
            }
        },

        ...mutations,
    },

    actions: {
    },

    modules: {
    },
});

store.subscribe((mutation, state) => {
    var userId = getUserId(state);

    // Presist states in local storage
    for (var moduleName in modules) {
        for (var mutationName in modules[moduleName].mutations) {
            if (mutation.type === mutationName) {
                for (var stateName in modules[moduleName].states) {
                    window.localStorage.setItem(`Vuex_User${userId}_${moduleName}_${stateName}`, JSON.stringify(state[stateName]));
                }
            }
        }
    }
});

store.onStorage = function (event) {
    if (event.key == null) {
        // storage.clear()
        for (var moduleName in modules) {
            for (var stateName in modules[moduleName].states) {
                store.commit('onStorage', { stateName: stateName });
            }
        }
    } else {
        // Try to get userId from event.key
        var userId = Number.NaN;
        var keyParts = event.key.split('_');
        if (keyParts.length >= 4 && keyParts[0] === 'Vuex' && keyParts[1].startsWith('User')) {
            userId = Number.parseInt(keyParts[1].substring(4));
        }

        if (!Number.isNaN(userId)) {
            // Notify change of states in local storage
            for (var moduleName in modules) {
                for (var stateName in modules[moduleName].states) {
                    if (event.key === `Vuex_User${userId}_${moduleName}_${stateName}`) {
                        store.commit('onStorage', { stateName: stateName, oldValue: event.oldValue, newValue: event.newValue, userId: userId });
                    }
                }
            }
        }
    }
}

export default store;
