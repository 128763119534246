export default {
    states: {
        browsingClientId: -1,
        browsingClientIdChangeComplete: -1,
    },

    mutations: {
        setBrowsingClient(state, id) {
            state.browsingClientId = id;
        },

        setBrowsingClientComplete(state) {
            state.browsingClientIdChangeComplete++;
        },
    },
}