import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//import {
//    faMinus, faBolt, faCheck, faExclamation, faExchangeAlt, faCloudDownloadAlt, faChevronRight,
//    faCircle
//} from '@fortawesome/free-regular-svg-icons'
// faTowerBroadcast
import {
    faMinus, faBolt, faCheck, faExclamation, faExchangeAlt, faCloudDownloadAlt, faChevronRight, faDrawPolygon,
    faUsers, faAddressBook, faCircle, faDesktop, faCommentDots, faCloud, faListAlt, faAddressCard, faMapMarkerAlt,
    faNetworkWired, faHourglassHalf, faThumbsUp, faThumbsDown, faBroadcastTower, faExpandAlt, faChevronDown, faChevronUp,
    faGripVertical, faCalculator
} from '@fortawesome/free-solid-svg-icons'

import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons'

library.add(
    faCircle,
    faMinus,
    faBolt,
    faCheck,
    faExclamation,
    faExchangeAlt,
    faCloudDownloadAlt,
    faChevronRight,
    faDrawPolygon,
    faUsers,
    faAddressBook,
    farCircle,
    faCircle,
    faDesktop,
    faCommentDots,
    faCloud,
    faListAlt,
    faAddressCard,
    faMapMarkerAlt,
    faNetworkWired,
    faHourglassHalf,
    faThumbsUp,
    faThumbsDown,
    //faTowerBroadcast
    faBroadcastTower,
    faExpandAlt,
    faChevronDown,
    faChevronUp,
    faGripVertical,
    faCalculator
)

export {
    FontAwesomeIcon as default,
    FontAwesomeIcon as FontAwesomeIcon,
}
