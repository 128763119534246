export default {
    data: function () {
        return {
            menuItems: [
                {
                    text: this.$t('nav.dashboard'),
                    icon: 'g:dashboard:0:400',
                    to: '/dashboard',
                },
                {
                    text: this.$t('nav.notifications'),
                    icon: 'g:assignment:0:400',
                    classes: [],
                    expanded: false,
                    neverHide: true,
                    menuItems: [
                        {
                            text: this.$t('nav.alarms'),
                            icon: 'g:alarm:0:300',
                            to: '/notifications/alarms',
                            permissions: ['EVENT_READ']
                        },
                        {
                            text: this.$t('nav.alerts'),
                            icon: 'g:notifications:0:300',
                            to: '/notifications/alerts',
                            permissions: ['EVENT_READ']
                        },                       
                        
                        {
                            text: this.$t('nav.client-messages'),
                            icon: 'g:mail:0:300',
                            to: '/notifications/client-messages',
                            permissions: ['CLIENTMESSAGE_READ']
                        }
                    ],
                },
                {
                    text: this.$t('nav.sites'),
                    icon: 'g:home-work:0:400',
                    expanded: false,
                    menuItems: [
                        {
                            type: "client",
                            text: (this.$auth.user().browsingClient.isEngineer ? this.$t("nav.site-owners") : this.$t("nav.installers")),
                            icon: 'g:communities:0:300',
                            to: '/management/client',
                            permissions: ['CLIENT_MANAGEOWN']
                        },
                        {
                            text: this.$t('nav.areas'),
                            icon: 'g:room-preferences:0:300',
                            to: '/administration/areas',
                            permissions: ['AREA_READ']
                        },
                        {
                            text: this.$t('nav.site-plans'),
                            icon: 'g:photo-library:0:300',
                            to: '/administration/site-plans',
                            permissions: ['AREA_READ']
                        },
                        {
                            text: this.$t('nav.door-groups'),
                            icon: 'g:cards:1:300',
                            to: '/administration/door-groups',
                            permissions: ['DOORGROUP_READ']
                        },
                        {
                            text: this.$t('nav.zones'),
                            icon: 'g:activity-zone:1:300',
                            to: '/administration/zone',
                            permissions: ['ZONE_READ']
                        },
                        {
                            text: this.$t('nav.hardware-management'),
                            icon: 'g:videocam:0:300',
                            to: '/management/controllers',
                            permissions: []
                        },
                    ],
                },
                {
                    text: this.$t('nav.people'),
                    icon: 'g:person:0:400',
                    expanded: false,
                    menuItems: [
                        {
                            text: this.$t('nav.users'),
                            icon: 'g:account-box:0:300',
                            to: '/administration/people',
                            permissions: ['PERSON_READ']
                        },
                        {
                            text: this.$t('nav.groups'),
                            icon: 'g:group-add:0:300',
                            to: '/administration/groups',
                            permissions: ['GROUP_READ']
                        },
                        {
                            text: this.$t('nav.website-admin'),
                            icon: 'g:admin-panel-settings:0:300',
                            to: '/settings/users',
                            permissions: ['USER_READ']
                        },
                        {
                            text: this.$t('nav.announcements'),
                            icon: 'g:chat:0:300',
                            to: '/settings/announcement',
                            permissions: []
                        },
                    ],
                },
                {
                    text: this.$t('nav.access'),
                    icon: 'g:door-open:0:400',
                    expanded: false,
                    menuItems: [
                        {
                            text: this.$t('nav.cloud-tokens'),
                            icon: 'g:vpn-key:0:300',
                            to: '/administration/fob/available-cloud-fobs',
                            permissions: ['FOB_READ']
                        },
                        {
                            text: this.$t('nav.smart-credentials'),
                            icon: 'g:phonelink-ring:0:300',
                            to: '/administration/fob/available-cloud-fobs',
                            permissions: ['FOB_READ']
                        },
                        {
                            text: this.$t('nav.door-unlock-invitation'),
                            icon: 'g:lock-open:0:300',
                            to: '/administration/door-unlock-invitations',
                            permissions: ['DOORUNLOCKINVITATION_READ']
                        },
                        {
                            text: this.$t('nav.badge-designs'),
                            icon: 'g:shapes:0:300',
                            to: '/administration/badge-designs',
                            permissions: [this.isACS ? 'KMSUSER_USER' : 'SOME_PERMISSION_DOES_NOT_EVER_EXISTS']
                        }
                    ],
                },
                {
                    text: this.$t('nav.rules'),
                    icon: 'g:inventory:0:400',
                    expanded: false,
                    menuItems: [
                        {
                            text: this.$t('nav.time-profiles'),
                            icon: 'g:schedule:0:300',
                            to: '/management/time-profile-acs',
                            permissions: ['TIMEPROFILE_READ']
                        },
                        
                        {
                            text: this.$t('nav.alert-triggers'),
                            icon: 'g:warning:0:300',
                            to: '/monitoring/alert-trigger',
                            permissions: ['ALERTTRIGGER_READ']
                        },
                        {
                            text: this.$t('nav.alert-people'),
                            icon: 'g:campaign:0:300',
                            to: '/monitoring/alert-person',
                            permissions: ['ALERTPERSON_READ']
                        },
                    ]
                },
                {
                    text: this.$t('nav.monitoring'),
                    icon: 'g:browse-activity:0:400',
                    expanded: false,
                    menuItems: [
                        {
                            text: this.$t('nav.time-and-attendance'),
                            icon: 'g:work-history:0:300',
                            to: '/monitoring/time-and-attendance',
                            permissions: ['PERSON_READ']
                        },
                        {
                            text: this.$t('nav.roll-call'),
                            icon: 'g:location-away:0:300',
                            to: '/monitoring/roll-call',
                            permissions: ['PERSON_READ',"ZONE_READ"]
                        },
                        {
                            text: this.$t('nav.event-log'),
                            icon: 'g:calendar-month:0:300',
                            to: '/monitoring/event-log',
                            permissions: ['EVENT_READ']
                        },
                        {
                            text: this.$t('nav.reports'),
                            icon: 'g:monitoring:0:300',
                            to: '/monitoring/report',
                            permissions: ['EVENT_READ']
                        },
                        
                    ]
                },
                {
                    text: this.$t('nav.orders'),
                    icon: 'g:sell:0:400',
                    expanded: false,
                    menuItems: [
                        {
                            text: this.$t('nav.shop'),
                            icon: 'g:shopping-cart:0:300',
                            to: '/shop',
                            permissions: ['SHOPORDER_ADD'],
                        },
                        {
                            text: this.$t('nav.hosting-contracts'),
                            icon: 'g:credit-card:0:300',
                            to: '/management/hosting-contracts',
                            permissions: ['HOSTINGCONTRACT_READ'],
                        },
                        {
                            text: this.$t('nav.orders'),
                            icon: 'g:receipt-long:0:300',
                            to: '/shop/order',
                            permissions: ['SHOPORDER_READ'],
                        },
                        {
                            text: this.$t('nav.stockroom'),
                            icon: 'g:inventory-2:0:300',
                            to: '/management/stockroom',
                            permissions: ['CLIENT_MANAGEOWN']
                        }
                    ]
                },
                {
                    text: this.$t('nav.help'),
                    icon: 'g:help:0:400',
                    to: 'https://help-beta.cameacs.dev?token=' + this.$auth.token(),
                    target: '_blank',
                }
            ]
        }
    },
    methods: {
        getMenuItemsSearch(searchString, items, parentBreadcrumb) {
            var matchingItems = [];

            for (var i = 0; i < items.length; i++) {
                var currentItem = items[i];

                var currentBreadcrumb = parentBreadcrumb + currentItem.text;

                if (currentItem.menuItems && currentItem.menuItems.length) {
                    currentBreadcrumb += " > ";

                    var matchingChildItems = this.getMenuItemsSearch(searchString, currentItem.menuItems, currentBreadcrumb);

                    matchingItems = matchingItems.concat(matchingChildItems);
                } else {
                    if (currentItem.text.toLowerCase().includes(searchString.toLowerCase())) {
                        matchingItems.push({
                            title: currentItem.text,
                            description: currentBreadcrumb,
                            url: currentItem.to,
                            icon: currentItem.icon
                        })
                    }
                }
            }

            return matchingItems;
        }
    }
}