export default {
    states: {
        commonPasswords: [],
        commonPasswordsTimestamp: 0,
        accountChangeComplete: 0,
    },

    mutations: {
        setCommonPasswords(state, newValues) {
            state.commonPasswords = newValues;
            state.commonPasswordsTimestamp = Date.now();
        },
        notifyAccountChangeComplete(state) {
            state.accountChangeComplete++;
        },
    },
}