<template>

    <div class="border-end" id="sidebar-wrapper">

        <div class="sliding-menu-wrapper" ref="slidingMenuWrapper">

            <div :class="['list-group', 'list-group-flush', 'sliding-sub-menu', currentMenu == 'main' ? 'active' : 'out']">

                <router-link class="list-group-item list-group-item-action list-group-item-kms"
                             :to="'/account'">{{ $auth.user().user.displayName }}</router-link>

                <div class="list-group-item-kms list-group-item-action list-group-item "
                     v-if="browsingClient && switchableClients.length > 0" @click="currentMenu = 'clients'">
                    <i class="side-bar-arrow float-right mr-2"></i>
                    {{ browsingClient.name }}
                </div>
                <div class="list-group-item list-group-item-kms"
                     v-else><b-skeleton /></div>

                <div class="list-group-item list-group-item-action list-group-item-kms"
                     v-for="section in getUserMenuItems(menuItems)"
                     :key="section.name"
                     @click="currentMenu = section.name">
                    <i class="side-bar-arrow float-right mr-2"></i>
                    {{ section.name }}
                </div>

                <!--<a class="list-group-item list-group-item-action list-group-item-kms"
                :href="'//help.kms.uk.net/login?user=' + $auth.user().user.client.domain + '%5C' + $auth.user().user.userName" target="_blank"> {{ $t('nav.help') }} </a>-->
                <a class="list-group-item list-group-item-action list-group-item-kms"
                   :href="'//help.kms.uk.net?token=' + $auth.token()" target="_blank"> {{ $t('nav.help') }} </a>

                <a class="list-group-item list-group-item-action list-group-item-kms"
                   href="#" @click.prevent="logout">{{ $t('nav.logout') }}</a>

            </div>

            <div :class="['list-group', 'list-group-flush', 'sliding-sub-menu', currentMenu == 'clients' ? 'active' : '']"
                 v-on:keyup.down="switchableClientsSelectedIndex < filteredswitchableClients.length ? switchableClientsSelectedIndex++ : switchableClientsSelectedIndex = 0"
                 v-on:keyup.up="switchableClientsSelectedIndex > 0 ? switchableClientsSelectedIndex-- : switchableClientsSelectedIndex = filteredswitchableClients.length"
                 v-on:keyup.enter="handleChangeClientEnter"
                 @mouseover="switchableClientsSelectedIndex = -1"
                 @mouseleave="switchableClientsSelectedIndex = -1">

                <div class="list-group-item list-group-item-action list-group-item-kms"
                     @click="currentMenu = 'main'">
                    <i class="side-bar-arrow back mr-4"></i>
                    {{ $t('nav.back') }}
                </div>

                <div class="list-group-item list-group-item-kms">
                    <input type="text"
                           class="form-control"
                           :placeholder="$t('nav.client-filter')"
                           v-model="switchableClientsFilter"
                           ref="switchableClientsFilterInput" />
                </div>

                <!--<router-link v-for="(client, index) in filteredswitchableClients" :key="client.clintId"
                         :class="['list-group-item', 'list-group-item-action', 'list-group-item-kms', index == switchableClientsSelectedIndex ? 'active' : '']"
                         :to="'/configuration/client/' + client.clientId">{{client.name}}</router-link>-->

                <div v-for="(client, index) in filteredswitchableClients" :key="client.clintId"
                     :class="['list-group-item', 'list-group-item-action', 'list-group-item-kms', index == switchableClientsSelectedIndex ? 'active' : '']"
                     @click="$store.commit('setBrowsingClient', client.clientId)">
                    {{client.name}}
                </div>

            </div>

            <div :class="['list-group', 'list-group-flush', 'sliding-sub-menu', currentMenu == section.name ? 'active' : '']"
                 v-for="section in menuItems"
                 :key="section.name">

                <div class="list-group-item list-group-item-action list-group-item-kms"
                     @click="currentMenu = 'main'">
                    <i class="side-bar-arrow back mr-4"></i>
                    {{ $t('nav.back') }}
                </div>

                <router-link v-for="menuItem in getUserSubMenuItems(section.menuItems)"
                             :key="menuItem.to"
                             class="list-group-item list-group-item-action list-group-item-kms"
                             :to="menuItem.to">{{menuItem.text}}</router-link>

            </div>
        </div>

        <div class="sidebar-footer">
            <img src="/images/came-kms-black-white.png" />
        </div>

        <b-modal :title="$t('client.setting-browsing-client')"
                 ref="loadingModal"
                 :hide-footer="true"
                 :no-close-on-backdrop="waiting"
                 :no-close-on-esc="waiting">
            <loading v-if="waiting"></loading>
        </b-modal>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                collapsed: false,
                currentMenu: 'main',
                switchableClients: [],
                switchableClientsFilter: '',
                switchableClientsSelectedIndex: -1,
                waiting: false
            }
        },
        computed: {
            menuItems() {
                return [{
                    name: this.$t('nav.administration'),
                    menuItems: [{
                        text: this.$t('nav.areas'),
                        to: '/administration/areas',
                        permissions: ['AREA_READ']
                    },
                    {
                        text: this.$t('nav.groups'),
                        to: '/administration/groups',
                        permissions: ['GROUP_READ']
                    },
                    {
                        text: this.$t('nav.cloud-tokens'),
                        to: '/administration/fob/available-cloud-fobs',
                        permissions: ['FOB_READ']
                    },
                    {
                        text: this.$t('nav.people'),
                        to: '/administration/people',
                        permissions: ['PERSON_READ']
                    },
                    {
                        text: this.$t('nav.notice-messages'),
                        to: '/administration/notice-messages',
                        permissions: ['NOTICEMESSAGE_READ']
                    },
                    {
                        text: this.$t('nav.door-unlock-invitation'),
                        to: '/administration/door-unlock-invitations',
                        permissions: ['DOORUNLOCKINVITATION_READ']
                    }
                    ]
                },
                {
                    name: this.$t('nav.management'),
                    menuItems: [{
                        text: this.$t('nav.system-summary'),
                        to: '/management/system/summary',
                        permissions: ['AREA_READ']
                    },
                    {
                        text: this.$t('nav.schematic'),
                        to: '/management/system/schematic',
                        permissions: ['COMMDEVICE_READ', 'CONTROLLER_READ', 'DOOR_READ']
                    },
                    {
                        text: this.$t('nav.system-transfer'),
                        to: '/management/system/transfer',
                        permissions: ['KMSUSER_USER']
                    },
                    {
                        text: this.$t('nav.time-profiles'),
                        to: '/management/time-profile',
                        permissions: ['TIMEPROFILE_READ']
                    },
                    {
                        text: this.$t('nav.comm-devices'),
                        to: '/management/comm-devices',
                        permissions: ['COMMDEVICE_READ']
                    },
                    {
                        text: this.$t('nav.hosting-contracts'),
                        to: '/management/hosting-contracts',
                        permissions: ['HOSTINGCONTRACT_READ']
                    },
                    {
                        text: this.$t('nav.sims'),
                        to: '/management/sims',
                        permissions: ['SIM_READ']
                    },
                    {
                        text: this.$t('nav.notice-boards'),
                        to: '/management/notice-boards',
                        permissions: ['DIGITALNOTICEBOARD_READ']
                    },
                    {
                        text: this.$t('nav.firmware-updates'),
                        to: '/management/system/firmware-updates',
                        permissions: ['SYSTEM_DEBUG']
                    },
                    {
                        text: this.$tc('objects.plurals.door-entry-link-door', 2),
                        to: '/management/door-entry-link-doors',
                        permissions: ['DOORENTRYCONFIGURATION_READ']
                    }
                    ],
                },
                {
                    name: this.$t('nav.monitoring'),
                    menuItems: [{
                        text: this.$t('nav.alert-people'),
                        to: '/monitoring/alert-person',
                        permissions: ['ALERTPERSON_READ']
                    },
                    {
                        text: this.$t('nav.alert-triggers'),
                        to: '/monitoring/alert-trigger',
                        permissions: ['ALERTTRIGGER_READ']
                    },
                    {
                        text: this.$t('nav.reports'),
                        to: '/monitoring/report',
                        permissions: ['EVENT_READ']
                    },
                    {
                        text: this.$t('nav.scheduled-reports'),
                        to: '/monitoring/scheduled-report',
                        permissions: ['SCHEDULEDREPORT_READ']
                    },
                    {
                        text: this.$t('nav.excessive-usage-dashboard'),
                        to: '/monitoring/excessive-usage-dashboard',
                        permissions: ['EXCESSIVEUSAGEDASHBOARD_READ']
                    }]
                },
                {
                    name: this.$t('nav.settings'),
                    menuItems: [{
                        text: this.$t('nav.me'),
                        to: '/account',
                        permissions: []
                    },
                    {
                        text: this.$t('nav.users'),
                        to: '/settings/users',
                        permissions: ['USER_READ']
                    },
                    {
                        text: this.$t('nav.user-groups'),
                        to: '/settings/user-groups',
                        permissions: ['ROLE_READ']
                    },
                    {
                        text: this.$t('nav.engineers'),
                        to: '/settings/engineers',
                        permissions: ['CLIENT_READ']
                    },
                    {
                        text: this.$t('nav.features'),
                        to: '/settings/features',
                        permissions: ['FEATURE_READ']
                    },

                    {
                        text: this.$t('nav.announcements'),
                        to: '/settings/announcement',
                        permissions: []
                    },
                    {
                        text: this.$t('nav.about'),
                        to: '/settings/about',
                        permissions: []
                    }]
                },
                {
                    name: this.$t('nav.configuration'),
                    menuItems: [{
                        text: this.$t('nav.area-types'),
                        to: '/configuration/area-types',
                        permissions: ['AREATYPE_READ']
                    },
                    {
                        text: this.$t('nav.sim-network-providers'),
                        to: '/configuration/sim-network-providers',
                        permissions: ['SKCONFIGURATION_READ']
                    },
                    {
                        text: this.$t('nav.sim-types'),
                        to: '/configuration/sim-types',
                        permissions: ['SKCONFIGURATION_READ']
                    },
                    {
                        text: this.$t('nav.fob-types'),
                        to: '/configuration/fob-types',
                        permissions: ['SKCONFIGURATION_READ']
                    },
                    {
                        text: this.$t('nav.fob-colours'),
                        to: '/configuration/fob-colour',
                        permissions: ['SKCONFIGURATION_READ']
                    },
                    {
                        text: this.$t('nav.door-types'),
                        to: '/configuration/door-types',
                        permissions: ['SKCONFIGURATION_READ']
                    },
                    {
                        text: this.$t('nav.lock-types'),
                        to: '/configuration/lock-types',
                        permissions: ['SKCONFIGURATION_READ']
                    },
                    {
                        text: this.$t('nav.clients'),
                        to: '/configuration/client',
                        permissions: ['CLIENT_READ']
                    },
                    {
                        text: this.$t('nav.engineer-relations'),
                        to: '/configuration/engineer-relations',
                        permissions: ['CLIENT_READ'],
                    }]
                }]
            },
            filteredswitchableClients() {
                return this.switchableClients.filter(c => c.name.toLowerCase().indexOf(this.switchableClientsFilter.toLowerCase()) > -1);
            },
            workingClientId() {
                return this.$auth.user().userData.workingClientId;
            },
            browsingClient() {
                if (!this.switchableClients)
                    return null;

                return this.switchableClients.find(c => c.clientId == this.workingClientId);
            }
        },
        methods: {
            getUserMenuItems(menuItems) {
                return menuItems.filter(i => this.getUserSubMenuItems(i.menuItems).length > 0);
            },
            getUserSubMenuItems(menuItems) {
                return menuItems.filter(i => i.permissions.every(p => this.$auth.check(p, 'permissions')));
            },
            toggleCollapsed: (event) => this.collapsed = !this.collapsed,
            async handleAuthChange(isAuthenticated) {

            },
            fixScroll() {
                this.$nextTick(() => {

                    var wrapper = this.$refs.slidingMenuWrapper;
                    var active = wrapper.getElementsByClassName('active')[0];

                    if (active.scrollHeight > wrapper.offsetHeight)
                        wrapper.style = 'overflow-y: auto';
                    else
                        wrapper.style = 'overflow-y: hidden';
                });

            },
            handleChangeClientEnter() {
                if (this.switchableClientsSelectedIndex < 1)
                    return;

                var selectedClient = this.filteredswitchableClients[this.switchableClientsSelectedIndex];
                this.handleClientPicked(selectedClient.clientId);
            },
            handleClientPicked: function (clientId) {
                this.waiting = true;
                this.switchableClientsFilter = '';
                this.$refs.loadingModal.show();

                this.$http.post('/user/' + this.$auth.user().user.userId + '/set-browsing-client', { clientId: clientId })
                    .then(this.handleSuccess)
                    .catch(this.handleError);
            },
            handleSuccess: function (response) {
                this.$auth.refresh({
                    success: function () {
                        this.$auth.fetch({
                            success: function () {
                                this.$notify({ type: 'alert-success', text: 'Successfully changed browsing client' });
                                this.handleComplete();
                                this.$refs.loadingModal.hide();

                                //this.$debug?.log('this.$router.history._startLocation: ' + this.$router.history._startLocation)
                                //var fullPath = this.$router.history._startLocation;
                                var fullPath = "/";

                                if (this.$router.currentRoute.fullPath == "/client-mismatch")
                                    fullPath = this.$router.currentRoute.params.url.fullPath;
                                else
                                    fullPath = this.$router.currentRoute.fullPath;

                                this.$router.replace('/blank').then(() => {
                                    if (fullPath == '/blank')
                                        return;
                                    this.$router.replace(fullPath);
                                    this.currentMenu = 'main';
                                });
                                this.$store.commit('setBrowsingClientComplete');
                                this.currentMenu = 'main';
                            }
                        });
                    }
                });

                //window.location.reload(false);
            },
            handleError: function (error) {
                this.$debug?.log('Error changing browsing client: ' + error);
                this.$notify({ type: 'alert-danger', title: 'Error changing browsing client', text: error.message });
                this.handleComplete();
            },
            handleComplete: function () {
                this.waiting = false;
            },
            logout: function () {
                this.$auth.logout({
                    makeRequest: false,
                    params: {},
                    success: () => {
                        this.$notify({ type: 'alert-success', text: 'Successfully logged out' })
                    },
                    error: (response) => this.$notify({ type: 'alert-danger', title: 'Failed to logout', text: response.data.message }),
                    redirect: '/login',
                });
            }
        },
        async created() {
            await this.handleAuthChange(false);
            this.$watch(() => this.$auth.check(), this.handleAuthChange);

            var userId = this.$auth.user().user.userId;
            this.switchableClients = (await this.$http.get(`/user/${userId}/switchable-clients`)).data;
        },
        watch:
        {
            currentMenu() {
                this.fixScroll();

                if (this.currentMenu == 'clients')
                    this.$refs.switchableClientsFilterInput.focus({ preventScroll: true });

                this.switchableClientsSelectedIndex = -1;
            },
            switchableClientsFilter() {
                this.switchableClientsSelectedIndex = -1;

            },
            $route(to, from) {
                this.currentMenu = 'main';
            },
            '$store.state.browsingClientId': function (newBrowsingClientId) {
                this.handleClientPicked(newBrowsingClientId);
            }

        },
        mounted() {
            this.fixScroll();
        }
    }
</script>


<style scoped>

    #sidebar-wrapper {
        width: 300px;
        position: fixed;
        top: 67px;
        left: 0;
        bottom: 0;
        padding-bottom: 60px;
        padding-top: 4rem;
        margin-left: -300px;
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;
        /*background-color: #000 !important;*/
        background-color: #00b0ed !important;
    }

        #sidebar-wrapper .sidebar-footer {
        }

            #sidebar-wrapper .sidebar-footer img {
                position: fixed;
                bottom: 10px;
                width: 270px;
                margin-left: 15px;
            }

    body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
        margin-left: 0;
    }

    @media (min-width: 768px) {
        #sidebar-wrapper {
            margin-left: 0;
        }

        body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
            margin-left: -300px;
        }
    }

    @media (max-width: 977px) {
        #sidebar-wrapper {
            min-height: calc(100vh - 155px) !important;
        }
    }


    @media (max-width: 991px) {
        #sidebar-wrapper {
            top: 111px;
            height: calc(100% - 60px)
        }
    }
</style>