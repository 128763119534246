////////////////////////////
// Auto height of element //
////////////////////////////
let autoHeightArray = [];

let autoHeightHandler = function (event, context) {
    let elementRect = context.element.getBoundingClientRect();
    let containerRect = context.container ? context.container.getBoundingClientRect() : document.body.getBoundingClientRect();
    let bodyRect = document.body.getBoundingClientRect();

    // Do nothing if container is not visible
    if (containerRect.top > bodyRect.bottom || context.container.bottom < bodyRect.top) return;
    // Do nothing if element is not visible
    if (elementRect.top > containerRect.bottom || elementRect.bottom < containerRect.top + context.offset) return;

    let elementHeight = containerRect.bottom - elementRect.top - context.margin;
    let maxHeight = containerRect.height - context.offset - context.margin - context.margin;
    if (elementHeight > maxHeight) elementHeight = maxHeight;
    if (context.minHeight && elementHeight < context.minHeight) elementHeight = context.minHeight;
    if (context.maxHeight && elementHeight > context.maxHeight) elementHeight = context.maxHeight;

    context.element.style.height = `${elementHeight}px`;
};

///////////////////////
// Resize of element //
///////////////////////
let resizeArray = [];

let resizeHandler = function (entries, context) {
    if (!entries || !entries[0] || entries[0].target != context.element) return;

    window.requestAnimationFrame(() => {
        try {
            // Generally equals to sending border width and content width to handler
            context.handler(entries[0].borderBoxSize[0].inlineSize, entries[0].contentBoxSize[0].inlineSize);
        } catch (error) {
            console.log('error', error);
        } finally {
        }
    });
};

export default {
    distinctFilter(value, index, array) {
        return array.indexOf(value) === index;
    },

    computed: {
        searchParam() {
            return window?.location?.search;
        },
    },

    methods: {
        // Public use
        stopEvent(event) {
            event.stopPropagation();
            event.preventDefault();
            event.cancelBubble = true;
            event.returnValue = false;
        },

        // Public use
        stopBubble(event) {
            event.stopPropagation();
            event.cancelBubble = true;
        },

        // Public use
        // Work in local time zone within the browser, so use Date.getXXX()
        toLocalIsoDateString(date) {
            return date.getFullYear().toString().padStart(4, '0') + '-' +
                (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
                date.getDate().toString().padStart(2, '0');
        },

        // Public use
        // Work in local time zone within the browser, so use new Date(yyyy, mm, dd)
        parseLocalIsoDateString(string) {
            let parts = string.split('-');
            let yyyy = Number.parseInt(parts[0]);
            let mm = Number.parseInt(parts[1]);
            let dd = Number.parseInt(parts[2]);
            if (!Number.isNaN(yyyy) && !Number.isNaN(mm) && !Number.isNaN(dd)) {
                return new Date(yyyy, mm - 1, dd);
            } else {
                return new Date(Number.NaN);
            }
        },

        // Public use
        getValueByPath(obj, path) {
            path = path.split('.');
            path = path.flatMap(i => i.split('['));

            path = path.map(i => {
                if (i.endsWith(']')) {
                    i = i.slice(0, i.length - 1);
                    let value = Number.parseInt(i);
                    if (Number.isInteger(value)) return value;
                    else return i;
                } else return i;
            });

            path = path.map(i => {
                if (i.length > 2 && i.startsWith('"') && i.endsWith('"')) {
                    i = i.slice(1, i.length - 1);
                    return i;
                } else if (i.length > 2 && i.startsWith('\'') && i.endsWith('\'')) {
                    i = i.slice(1, i.length - 1);
                    return i;
                } else return i;
            });

            return path.reduce((source, key) => source == null ? null : source == undefined ? undefined : source[key], obj);
        },

        parseProp(propName, type, dataName, ignoreNullValue) {
            if (this[propName] == null) {
                if (this[dataName] != null) {
                    if (!ignoreNullValue) this[dataName] = null;
                }
            } else {
                if (type == Number) {
                    let number = Number.parseFloat(this[propName]);
                    if (!Number.isNaN(number) && this[dataName] != number) this[dataName] = number;
                } else if (type == Date) {
                    let date = new Date(this[propName]);
                    if (!isNaN(date) && (this[dataName] == null || this[dataName].getTime() != date.getTime())) this[dataName] = date;
                } else if (type == String) {
                    let string = this[propName]?.toString();
                    if (this[dataName] != string) this[dataName] = string;
                } else if (type == Array) {
                    let array = this[propName];
                    if (Array.isArray(array) && this[dataName] != array) this[dataName] = array;
                } else if (type == Object) {
                    let obj = this[propName];
                    if (this[dataName] != obj) this[dataName] = obj;
                }
            }
        },

        emitProp(propName, dataName) {
            if (this[propName] !== this[dataName]) {
                if (propName == 'value') this.$emit('input', this[dataName]);
                else this.$emit('update:' + propName, this[dataName]);
            }
        },

        // Public use
        syncProps() {
            for (let dataName in this.$data) {
                if (dataName.endsWith('Number')) {
                    // Sync between my prop and my data, using Number as type
                    let propName = dataName.substring(0, dataName.length - 6);
                    if (propName in this.$props) this.syncProp(propName, Number, dataName);
                } else if (dataName.endsWith('Date')) {
                    // Sync between my prop and my data, using Date as type
                    let propName = dataName.substring(0, dataName.length - 4);
                    if (propName in this.$props) this.syncProp(propName, Date, dataName);
                } else if (dataName.endsWith('String')) {
                    // Sync between my prop and my data, using String as type
                    let propName = dataName.substring(0, dataName.length - 6);
                    if (propName in this.$props) this.syncProp(propName, String, dataName);
                } else if (dataName.endsWith('Array')) {
                    // Sync between my prop and my data, using Array as type
                    let propName = dataName.substring(0, dataName.length - 5);
                    if (propName in this.$props) this.syncProp(propName, Array, dataName);
                } else if (dataName.endsWith('Object')) {
                    // Sync between my prop and my data, using Object as type
                    let propName = dataName.substring(0, dataName.length - 6);
                    if (propName in this.$props) this.syncProp(propName, Object, dataName);
                }
            }
        },

        // Public use
        syncProp(propName, type, dataName) {
            if (!dataName) {
                if (type == Number) dataName = propName + 'Number';
                else if (type == Date) dataName = propName + 'Date';
                else if (type == String) dataName = propName + 'String';
                else if (type == Array) dataName = propName + 'Array';
                else if (type == Object) dataName = propName + 'Object';
            }

            this.parseProp(propName, type, dataName, true); // Ignore initial value of null prop and keep default value in data
            this.emitProp(propName, dataName);
            this.$watch(propName, () => this.parseProp(propName, type, dataName, false));
            this.$watch(dataName, () => this.emitProp(propName, dataName));
        },

        parseSearchParam(searchParamName, type, dataName, ignoreNullValue, defaultValue) {
            let params = new URLSearchParams(window.location.search);

            if (!params.has(searchParamName)) {
                //if (this[dataName] != null) {
                //    if (!ignoreNullValue) this[dataName] = null;
                //}
                if (type == Date) {
                    if (!(this[dataName] instanceof Date) || !(defaultValue instanceof Date) || this[dataName].getTime() != defaultValue.getTime()) this[dataName] = defaultValue;
                } else {
                    if (this[dataName] != defaultValue) this[dataName] = defaultValue;
                }
            } else {
                if (type == Number) {
                    let number = Number.parseFloat(params.get(searchParamName));
                    if (!Number.isNaN(number) && this[dataName] != number) this[dataName] = number;
                } else if (type == Date) {
                    // Date only, datetime is not supported
                    // Work in local time zone within the browser, so use new Date(yyyy, mm, dd)
                    let date = this.parseLocalIsoDateString(params.get(searchParamName));
                    if (!isNaN(date) && (this[dataName] == null || this[dataName].getTime() != date.getTime())) this[dataName] = date;
                } else if (type == String) {
                    let string = params.get(searchParamName);
                    if (this[dataName] != string) this[dataName] = string;
                } else if (type == Object) {
                    // Object is not supported now, maybe base64(json()) later
                } else {
                    // Not supported
                }
            }
        },

        writeSearchParam(searchParamName, type, dataName) {
            let params = new URLSearchParams(window.location.search);
            let currentFullPath = params.toString()

            if (this[dataName] == null) {
                params.delete(searchParamName);
            } else {
                if (type == Number) {
                    params.set(searchParamName, this[dataName].toString());
                } else if (type == Date) {
                    // Date only, datetime is not supported
                    // Work in local time zone within the browser, so use Date.getXXX()
                    params.set(
                        searchParamName,
                        this.toLocalIsoDateString(this[dataName]),
                    );
                } else if (type == String) {
                    if (this[dataName]) {
                        params.set(searchParamName, this[dataName].toString());
                    } else {
                        params.delete(searchParamName);
                    }
                } else if (type == Object) {
                    // Object is not supported now, maybe base64(json()) later
                } else {
                    // Not supported
                }
            }

            let fullPath = params.toString();
            if (fullPath != currentFullPath) {
                if (fullPath) {
                    fullPath = this.$route.path + '?' + fullPath;
                } else {
                    fullPath = this.$route.path;
                }
                fullPath = fullPath + this.$route.hash;

                if (this.$route.fullPath != fullPath) this.$router.replace(fullPath);
            }
        },

        // Public use
        syncSearchParam(searchParamName, type, dataName) {
            if (!dataName) dataName = searchParamName;
            let defaultValue = this[dataName];
            let writingNull = false;
            this.parseSearchParam(searchParamName, type, dataName, true, defaultValue); // Ignore initial value of null prop and keep default value in data
            this.writeSearchParam(searchParamName, type, dataName);
            this.$watch(`$route.query.${searchParamName}`, (newValue) => {
                if (writingNull && (newValue == null || newValue == undefined)) {
                    writingNull = false;
                } else {
                    this.parseSearchParam(searchParamName, type, dataName, false, defaultValue);
                }
            });
            this.$watch(dataName, (newValue) => {
                if (newValue == null || newValue == undefined) writingNull = true;
                this.writeSearchParam(searchParamName, type, dataName);
            });
        },

        // Public use
        setupRouterLink(element) {
            // Change the behaviour of <a> inside the specified element to <router-link>
            if (element && typeof element.getElementsByTagName == 'function') {
                let anchors = element.getElementsByTagName('a');
                for (let anchor of anchors) {
                    let href = anchor.getAttribute('href');

                    if (!href.includes('://')) {
                        // Process relative URLs only
                        anchor.addEventListener('click', (event) => {
                            this.$router.push(event.target.getAttribute('href'));
                            event.preventDefault();
                        });
                    }
                }
            }
        },

        // Public use
        // Wait for a ref to be rendered
        async waitRef(ref, timeout) {
            let startTime = Date.now();

            while (true) {
                if (this.$refs[ref]) return this.$refs[ref];
                if (timeout && Date.now() > startTime + timeout) return null;

                await new Promise((resolve) => window.setTimeout(resolve, 10));
                await this.$nextTick();
            }
        },

        // Public use
        // Automate the height of the element while scrolling and resizing
        setupAutoHeight(element, options) {
            if (!element) return;

            // Reference to the scroll container.
            // Default to body if not provided.
            let container = options && options.container ? options.container : null;
            // Offset at the top of the container.
            // Default to 110, the height of the sticky header of ACS, if not provided.
            let offset = options && options.offset ? options.offset : null;
            // Margins at the top and bottom of the element between the container (or body).
            let margin = options && options.margin ? options.margin : null;
            let minHeight = options && options.minHeight ? options.minHeight : null;
            let maxHeight = options && options.maxHeight ? options.maxHeight : null;

            if (!container) container = document.body;

            let context = {
                component: this,
                element: element,
                container: container,
                //body: body,
                handler: null,
                offset: container === document.body ? (offset ? offset : 110) : (offset ? offset : 0),
                margin: margin ? margin : 15,
                minHeight: minHeight ? minHeight : 480,
                maxHeight: maxHeight ? maxHeight : 1920,
            };
            context.handler = (event) => autoHeightHandler(event, context);

            window.addEventListener('resize', context.handler);
            context.container.addEventListener('scroll', context.handler);
            context.handler(null);

            autoHeightArray.push(context);
            return context;
        },

        // Public
        // Remove event handlers of auto height gracefully
        removeAutoHeight(context) {
            let index = autoHeightArray.findIndex(c => c === context);

            if (index >= 0) {
                let removedArray = autoHeightArray.splice(index, 1);

                for (const removed of removedArray) {
                    window.removeEventListener('resize', removed.handler);
                    removed.container.removeEventListener('scroll', removed.handler);
                };
            }
        },

        // Public use
        setupResize(element, handler) {
            if (!element) return;

            let context = {
                component: this,
                element: element,
                handler: handler,
                observer: null,
                resizing: false,
                lastWidth: 0,
            };

            context.observer = new ResizeObserver((entries) => {
                resizeHandler(entries, context);
            });
            context.observer.observe(element);

            resizeArray.push(context);
            return context;
        },

        removeResize(context) {
            let index = resizeArray.findIndex(c => c === context);

            if (index >= 0) {
                let removedArray = resizeArray.splice(index, 1);

                for (const removed of removedArray) {
                    removed.observer.disconnect();
                };
            }
        },
    },

    beforeDestroy() {
        // Remove all auto height of this component
        let removingAutoHeight = autoHeightArray.find(context => context.component === this);

        while (removingAutoHeight) {
            this.removeAutoHeight(removingAutoHeight);
            removingAutoHeight = autoHeightArray.find(context => context.component === this);
        }

        // Remove all resize of this component
        let removingResize = resizeArray.find(context => context.component === this);

        while (removingResize) {
            this.removeResize(removingResize);
            removingResize = resizeArray.find(context => context.component === this);
        }
    },
}