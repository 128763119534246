export default {
    // Mixin to update document title using the text contents of the first H1 element
    //
    // Please specify an HTML element (not Vue component) as the main element with ref="main"
    // This mixin:
    //     Look for the main element after route change
    //     If found, observe node changes under the main element
    //     After main mutations, find the first H1 element and treat it as the title element
    //     If found, observe node and text changes under the title element
    //     After title mutations, update document title using innerText()

    data() {
        return {
            pagetitle_mainFound: false,
            pagetitle_mainObserver: null,
            pagetitle_titleElement: null,
            pagetitle_titleObserver: null,
        }
    },

    methods: {
        // Contents of main changed
        // Look for the first h1 inside main element and set it as the title element
        pagetitle_handleMainMutation(mutationList, observer) {
            var h1 = this.$refs.main ? this.$refs.main.querySelector('h1') : null;

            if (h1) {
                if (!this.pagetitle_titleElement || this.pagetitle_titleElement != h1) {
                    this.pagetitle_titleElement = h1;

                    // Start observing for mutations of title element
                    this.pagetitle_titleObserver.observe(this.pagetitle_titleElement, { subtree: true, childList: true, characterData: true });
                }
            } else {
                this.pagetitle_titleElement = null;
            }

            // First time title mutation
            this.pagetitle_handleTitleMutation([{ target: this.pagetitle_titleElement }]);
        },

        // Contents of title changed
        pagetitle_handleTitleMutation(mutationList, observer) {
            if (this.pagetitle_titleElement) {
                // Get text inside the title element
                var texts = this.pagetitle_getTexts(this.pagetitle_titleElement);
                document.title = this.$t('common.title-prepend') + texts.join(' ') + this.$t('common.title-append');
            } else if (this.$route && this.$route.meta && typeof this.$route.meta.title == 'function') {
                // Fallback to page title from $route, if it exists
                var title = this.$route.meta.title(this.$route);
                if (typeof title == 'string') document.title = title;
            }
        },

        // Get an array of text in a node recursively
        pagetitle_getTexts(node) {
            var rtn = [];

            if (node && node.childNodes) {
                for (const childNode of node.childNodes) {
                    if (childNode.nodeName == '#text') {
                        if (childNode.data && childNode.data.trim()) rtn.push(childNode.data.trim());
                    } else {
                        Array.prototype.push.apply(rtn, this.pagetitle_getTexts(childNode));
                    }
                }
            }

            return rtn;
        },
    },

    watch: {
        '$route.path'() {
            if (this.$refs.main) {
                // Start observing for mutations of main
                this.pagetitle_mainObserver.observe(this.$refs.main, { subtree: true, childList: true });

                if (!this.pagetitle_mainFound) {
                    // First time main mutation
                    this.pagetitle_mainFound = true;
                    this.pagetitle_handleMainMutation();
                }
            } else {
                this.pagetitle_mainFound = false;
            }
        },
    },

    created() {
        this.pagetitle_mainObserver = new MutationObserver(this.pagetitle_handleMainMutation);
        this.pagetitle_titleObserver = new MutationObserver(this.pagetitle_handleTitleMutation);
    },

    beforeDestroy() {
        if (this.pagetitle_mainObserver) this.pagetitle_mainObserver.disconnect();
        if (this.pagetitle_titleObserver) this.pagetitle_titleObserver.disconnect();
    },
}