export default {
    states: {
        favouritesEdited: -1,
    },

    mutations: {
        editFavourite(state) {
            state.favouritesEdited++;
        },
    },
}