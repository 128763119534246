export default {
    install(Vue, options) {
        let isDebug = false;
        if (process.env.VUE_APP_ENVIRONMENT === 'beta') isDebug = true;
        else if (process.env.VUE_APP_ENVIRONMENT === 'development') isDebug = true;
        else if (process.env.NODE_ENV === 'development') isDebug = true;

        let logFunction = function () {
            var vueComponentName;
            if (this && this._isVue) {
                if (this.$options && typeof this.$options.__file == 'string') {
                    var parts = this.$options.__file.split('/');
                    vueComponentName = parts[parts.length - 1];
                } else {
                    vueComponentName = 'Anonymous Component';
                }
            }
            if (vueComponentName) console.group('Debug Log - ' + vueComponentName);
            else console.group('Debug Log');

            console.log.apply(null, arguments);
            var e = new Error();
            if (typeof e.stack == 'string') {
                var stacks = e.stack.split('\n');
                if (stacks[0] == 'Error') stacks = stacks.slice(1);
                if (stacks[0].includes('.isDebugTrue.log')) stacks = stacks.slice(1);
                if (stacks[0].includes('.$debugLog')) stacks = stacks.slice(1);
                if (stacks.length) {
                    console.groupCollapsed('Stack Trace');
                    for (const stack of stacks) {
                        console.log(stack);
                    }
                    console.groupEnd();
                }
            }

            if (this && this._isVue) {
                console.groupCollapsed('Vue');

                var component = this;
                while (component) {
                    if (component.$root && component.$root == component) {
                        console.group('Root');
                    } else if (component.$options && typeof component.$options.__file == 'string') {
                        var parts = component.$options.__file.split('/');
                        console.group(parts[parts.length - 1]);
                    } else {
                        console.group('Anonymous Component');
                    }
                    console.log(component);
                    console.groupEnd();

                    if (component.$parent && component.$parent._isVue) component = component.$parent;
                    else component = null;
                }

                if (this.$route) {
                    console.group('$route');
                    console.log(this.$route);
                    console.groupEnd();
                }

                if (this.$auth) {
                    var token = this.$auth.token();
                    if (token) {
                        var decoded = null;
                        if (this.$root && typeof this.$root.decodeJwt == 'function')
                            decoded = this.$root.decodeJwt(token);

                        console.group('$auth.token()');
                        if (decoded) console.log(decoded);
                        else console.log(token);
                        console.groupEnd();
                    }

                    var user = this.$auth.user();
                    if (user) {
                        console.group('$auth.user()');
                        console.log(user);
                        console.groupEnd();
                    }
                }

                if (this.$store && this.$store.state) {
                    console.group('$store.state');
                    console.log(this.$store.state);
                    console.groupEnd();
                }

                console.groupEnd();
            }

            console.groupEnd();
        };

        Object.defineProperty(Vue.prototype, '$debug', {
            get() {
                if (!isDebug) return null;

                let rtn = new Boolean(true);
                rtn.log = logFunction.bind(this);
                return rtn;
            },
            set(value) {
                if (value) {
                    isDebug = true;
                } else {
                    isDebug = false;
                }
            }
        });

        Vue.prototype.$debugLog = function () {
            if (isDebug) logFunction.apply(this, arguments);
        };
    },
}